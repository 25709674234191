import { render, staticRenderFns } from "./NoiseScenario.vue?vue&type=template&id=36073de5&scoped=true&"
import script from "./NoiseScenario.vue?vue&type=script&lang=ts&"
export * from "./NoiseScenario.vue?vue&type=script&lang=ts&"
import style0 from "./NoiseScenario.vue?vue&type=style&index=0&id=36073de5&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36073de5",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VContainer,VDataIterator,VFlex,VRadio,VRadioGroup,VSlider,VTooltip})
