import { render, staticRenderFns } from "./MenuComponentDivision.vue?vue&type=template&id=4bb34509&scoped=true&"
import script from "./MenuComponentDivision.vue?vue&type=script&lang=ts&"
export * from "./MenuComponentDivision.vue?vue&type=script&lang=ts&"
import style0 from "./MenuComponentDivision.vue?vue&type=style&index=0&id=4bb34509&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bb34509",
  null
  
)

export default component.exports