var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ ui_hide: !_vm.showUi, menu_open: _vm.menuOpen },attrs:{"id":"menu"}},[(_vm.windowWidth <= 1023)?_c('div',{staticClass:"mobile_button",on:{"click":function($event){_vm.menuOpen = !_vm.menuOpen}}},[(_vm.menuOpen)?[_c('v-icon',[_vm._v("mdi-close")])]:[_c('v-icon',[_vm._v("mdi-menu")])]],2):_vm._e(),_c('div',{staticClass:"menu_wrapper"},[(_vm.context == 'grasbrook')?_c('div',{staticClass:"header_scope"},[_c('h3',[_vm._v("COUP | Grasbrook")]),_c('p',[_vm._v("Cockpit for Collaborative Urban Planning ")])]):_vm._e(),(_vm.context == 'schb')?_c('div',{staticClass:"header_scope"},[_c('h3',[_vm._v("Science City")]),_c('p',[_vm._v("Competition Tool")])]):_vm._e(),_c('div',{staticClass:"component_switch"},[(_vm.windowWidth >= 720)?[_c('ul',{staticClass:"component_list"},[_c('li',{staticClass:"component_link",class:{
              selected: _vm.activeComponent === _vm.componentNames.pedestrian,
              waitingForResult: _vm.resultLoadingStati.pedestrian,
              //newResultNotification: notifyNewResult.pedestrian
            },on:{"click":function($event){(_vm.activeComponent = _vm.componentNames.pedestrian),
                (_vm.notifyNewResult.pedestrian = false)}}},[_c('p',[_vm._v("Pedestrians")])]),_c('li',{staticClass:"component_link",class:{
              selected: _vm.activeComponent === _vm.componentNames.stormwater,
              waitingForResult: _vm.resultLoadingStati.stormwater,
              //newResultNotification: notifyNewResult.stormwater
            },on:{"click":function($event){(_vm.activeComponent = _vm.componentNames.stormwater),
                (_vm.notifyNewResult.stormwater = false)}}},[_c('p',[_vm._v("Stormwater")])]),_c('li',{staticClass:"component_link",class:{
              selected: _vm.activeComponent === _vm.componentNames.noise,
              waitingForResult: _vm.resultLoadingStati.noise,
              //newResultNotification: notifyNewResult.noise
            },on:{"click":function($event){(_vm.activeComponent = _vm.componentNames.noise),
                (_vm.notifyNewResult.noise = false)}}},[_c('p',[_vm._v("Noise")])]),_c('li',{staticClass:"component_link",class:{
              selected: _vm.activeComponent === _vm.componentNames.wind,
              waitingForResult: _vm.resultLoadingStati.wind,
              //newResultNotification: notifyNewResult.wind
            },on:{"click":function($event){(_vm.activeComponent = _vm.componentNames.wind),
                (_vm.notifyNewResult.wind = false)}}},[_c('p',[_vm._v("Wind")])]),_c('li',{staticClass:"component_link",class:{
              selected: _vm.activeComponent === _vm.componentNames.sun,
              waitingForResult: _vm.resultLoadingStati.sun,
              //newResultNotification: notifyNewResult.sun
            },on:{"click":function($event){(_vm.activeComponent = _vm.componentNames.sun),
                (_vm.notifyNewResult.sun = false)}}},[_c('p',[_vm._v("Sun")])]),_c('li',{staticClass:"component_link",class:{
              selected: _vm.activeComponent === _vm.componentNames.multiLayer,
              waitingForResult: _vm.resultLoadingStati.multiLayer,
              //newResultNotification: notifyNewResult.multiLayer
            },on:{"click":function($event){(_vm.activeComponent = _vm.componentNames.multiLayer),
                (_vm.notifyNewResult.multiLayer = false)}}},[_c('p',[_vm._v("Combine Layers")])])])]:[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.activeComponent),expression:"activeComponent"}],staticClass:"mobile_select",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.activeComponent=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":_vm.componentNames.pedestrian}},[_vm._v("Pedestrians")]),_c('option',{domProps:{"value":_vm.componentNames.stormwater}},[_vm._v("Stormwater")]),_c('option',{domProps:{"value":_vm.componentNames.noise}},[_vm._v("Noise")]),_c('option',{domProps:{"value":_vm.componentNames.wind}},[_vm._v("Wind")]),_c('option',{domProps:{"value":_vm.componentNames.sun}},[_vm._v("Sun")]),_c('option',{domProps:{"value":_vm.componentNames.multiLayer}},[_vm._v("Combine Layers")])])]],2),_c('div',{staticClass:"body_scope"},[(_vm.activeComponent === _vm.componentNames.pedestrian)?_c('div',[_c('AbmScenario',{attrs:{"restrictedAccess":_vm.restrictedAccess,"context":_vm.context}})],1):_vm._e(),(_vm.activeComponent === _vm.componentNames.stormwater)?_c('div',[_c('StormwaterScenario')],1):_vm._e(),(_vm.activeComponent === _vm.componentNames.noise)?_c('div',[_c('NoiseScenario')],1):_vm._e(),(_vm.activeComponent === _vm.componentNames.wind)?_c('div',[_c('WindScenario')],1):_vm._e(),(_vm.activeComponent === _vm.componentNames.sun)?_c('div',[_c('SunExposureScenario')],1):_vm._e(),(_vm.activeComponent === _vm.componentNames.multiLayer)?_c('div',[_c('MultiLayerAnalysis')],1):_vm._e()]),_c('div',{staticClass:"footer_scope"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }