import { render, staticRenderFns } from "./Wind.vue?vue&type=template&id=2275dcc2&scoped=true&"
import script from "./Wind.vue?vue&type=script&lang=ts&"
export * from "./Wind.vue?vue&type=script&lang=ts&"
import style0 from "./Wind.vue?vue&type=style&index=0&id=2275dcc2&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2275dcc2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCardActions,VContainer,VDataIterator,VFlex,VSlider,VTooltip})
