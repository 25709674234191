// TODO : move this to config folder or something
export const alkisTranslations = {
  2000: "commercial",
  2020: "office",
  2050: "shop",
  2051: "department store",
  2054: "retail",
  2055: "kiosk",
  2056: "drug store",
  2071: "hotel",
  2080: "restaurant",
  2081: "restaurant",
  2083: "cafeteria",
  2084: "cafe",
  2085: "bakery",
  2086: "fast food",
  2087: "bar",
  2100: "light industrial",
  1230: "supermarket",
  1010: "residential",
  1024: "student dormitory",
  1022: "senior living",
  1012: "large apartments",
  1011: "apartments",
  3000: "public",
  3023: "university",
  3025: "highSchool",
  3026: "elementary school",
  3030: "creative space",
  3033: "event space",
  3034: "museum",
  3037: "library",
  3040: "religious use",
  3053: "health center",
  3062: "community center",
  3065: "daycare",
  3210: "sports facility",
  3212: "sports facility",
  2612: "toilet public",
  1220: "medical practice",
  1000: "residential",
  1020: "dromitory",
  1100: "residential mixed use (general)",
  1120: "residential with retail and services",
  1130: "residential with commercial and industrial uses",
  1313: "allotment garden house",
  2010: "commercial and servcies",
  2052: "shopping center",
  2082: "hut",
  2090: "leisure",
  2092: "movie theater",
  2095: "gambling hall",
  2110: "manufacturing",
  2112: "production",
  2120: "workshop",
  2130: "gas station",
  2140: "warehouse",
  2143: "warehouse",
  2150: "logistics",
  2310: "commercial and services",
  2320: "commercial and industrial with residential",
  2420: "rail transport",
  2443: "lock",
  2460: "parking",
  2461: "parking garage",
  2463: "garage",
  2464: "vehicle depot",
  2500: "utilities",
  2510: "water utilities",
  2520: "electricity utilities",
  2540: "telecommunications",
  2570: "gas utilities",
  2611: "water treatment",
  2620: "waste processing",
  2621: "waste storage",
  2622: "waste incineration",
  3010: "public administrative servcies",
  3013: "post office",
  3014: "customs",
  3021: "school",
  3041: "church",
  3046: "mosque",
  3060: "social use",
  3061: "youth center",
  3070: "public safety",
  3072: "fire station",
  3074: "bunker",
  3094: "u-bahn station",
};
