import { render, staticRenderFns } from "./BuildingsLegend.vue?vue&type=template&id=cab9c4fa&scoped=true&"
import script from "./BuildingsLegend.vue?vue&type=script&lang=ts&"
export * from "./BuildingsLegend.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cab9c4fa",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VFlex } from 'vuetify/lib/components/VGrid';
installComponents(component, {VDataIterator,VFlex})
